import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Goldee = () => (
  <Layout>
    <SEO title="Goldee" />
    <h1>Hi I'm Goldee</h1>
    <p>An adorable and sassy yorkie gal. Adopted on May, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/goldee.jpg" alt="Goldee" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Goldee
